<template>
  <div id="editable-select">
    <div v-if="!isEdit" class="notEdit">
      <span>{{ label || value }} </span>
      <i
        class="iconfont icon-bianji"
        v-if="!isRead"
        title="编辑"
        @click="edit()"
      ></i>
    </div>
    <div v-else class="select">
      <el-select v-model="newVal"  placeholder="请选择">
        <el-option
          v-for="item in list"
          :key="option_key?item[option_key]:item.value"
          :label="option_label?item[option_label]:item.label"
          :value="option_key?item[option_key]:item.value"
        >
        </el-option>
      </el-select>
      <div class="operation">
        <i v-show="isLoading" slot="suffix" class="el-icon-loading"></i>
        <i
          slot="suffix"
          class="iconfont icon-zhengque"
          title="保存"
          @click="save()"
          v-show="!isLoading"
        ></i>
        <i
          slot="suffix"
          class="iconfont icon-cuowu"
          title="取消"
          @click="cancel()"
          v-show="!isLoading"
        ></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    //是否为只读模式
    isRead: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
    //修改的接口
    url: {
      type: String,
      default: "",
    },
    //修改的属性
    attr: {
      type: String,
      default: "",
    },
    //修改的原始值
    value: {
      type: [String, Number],
      default: "",
    },
    //显示的标签值
    label: {
      type: [String, Number],
      default: "",
    },
    //下拉框列表
    list: {
      type: Array,
      default: () => [],
    },
    //下拉框列表key
    option_key: {
      type: String,
      default: "",
    },
    // 下拉框列表label
    option_label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //是否编辑状态
      isEdit: false,
      //修改后的新值
      newVal: "",
      //保存之后的loading图标
      isLoading: false,
    };
  },
  methods: {
    edit() {
      this.newVal = this.value;
      this.isEdit = true;
    },
    async save() {
      try {
        this.isLoading = true;
        let res = await this.$http.post(this.url, {
          [this.attr]: this.newVal,
          ...this.params,
        });
        if (res.code === 0) {
          this.$message.success("编辑成功!");
          this.isEdit = false;
          //触发父组件传来的确认回调
          this.$emit("save");
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error("编辑失败!");
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      //触发父组件传来的取消回调
      this.$emit("cancel");
      this.newVal = this.value;
      this.isEdit = false;
    },
  },
};
</script>
<style lang="scss">
#editable-select {
  $color: rgba(0, 206, 192, 1);
  $color-hover: rgba(0, 206, 192, 0.6);
  width: 250px;
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  display: inline-block;
  .notEdit {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 16px;
    span {
      line-height: initial;
      word-break: break-all;
      margin-right: 10px;
    }
  }
  .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dcdfe6;
    padding-right: 4px;
    height: 100%;
    .el-select {
      width: 100%;
      .el-input {
        display: flex;
        align-items: center;
        .el-input__inner {
          border: none;
          font-size: 12px;
        }
      }
    }
    .operation {
      height: 100%;
    }
  }
  .iconfont,
  .el-icon-loading {
    cursor: pointer;
    color: $color;
    transition: all 0.3s;
    font-size: 18px;
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $color-hover;
    }
  }
  .el-icon-loading {
    font-size: 14px;
    cursor: initial;
  }
}
</style>
